<template>
  <div class="SafetyPerson">
    <SafetyMenu />
    <!-- 筛选项 -->
    <div class="wrap">
      <el-form :inline="true">
        <el-form-item label="项目名称">
          <el-select
            size="small"
            v-model="currentProject"
            placeholder="请选择项目"
          >
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.proName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <el-form :inline="true">
        <!-- 1巡检 2整改 -->
        <el-form-item label="人员类型">
          <span
            class="filterType"
            @click="safetyType = null"
            :class="[safetyType == null ? 'filterTypeActive' : '']"
            >全部</span
          >
          <span
            class="filterType"
            @click="safetyType = 1"
            :class="[safetyType == 1 ? 'filterTypeActive' : '']"
            >巡检人员</span
          >
          <span
            class="filterType"
            @click="safetyType = 2"
            :class="[safetyType == 2 ? 'filterTypeActive' : '']"
            >整改人员</span
          >
        </el-form-item>
      </el-form>
    </div>

    <!-- 功能按钮 -->
    <div class="wrap-blank">
      <el-button
        size="small"
        plain
        type="primary"
        icon="el-icon-plus"
        @click="showPerson = true"
        >添加安检人员</el-button
      >
    </div>
    <!-- 数据列表 -->
    <div class="wrap">
      <el-table :data="personList" style="width: 100%">
        <el-table-column prop="personName" label="姓名"></el-table-column>
        <el-table-column prop="personPhone" label="联系电话"></el-table-column>
        <el-table-column prop="safetyType" label="人员类型">
          <template slot-scope="scope">
            <span v-if="scope.row.safetyType == 1">巡检人员</span>
            <span v-if="scope.row.safetyType == 2">整改人员</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="onDel(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        layout="total, sizes, prev, pager, next"
        :page-size.sync="pageSize"
        :current-page.sync="pageNum"
        :page-sizes="[2, 5, 10, 20, 50, 100]"
        :total="totals"
        @current-change="getPersonList"
        @size-change="getPersonList"
      >
      </el-pagination>
    </div>

    <!-- 添加安检人员 -->
    <el-dialog
      title="安检人员"
      :visible.sync="showPerson"
      style="width: 800px; margin: 0 auto"
    >
      <el-radio v-model="newSafetyType" :label="1">巡检人员</el-radio>
      <el-radio v-model="newSafetyType" :label="2">整改人员</el-radio>

      <el-table :data="userListData" style="width: 100%; margin-top: 16px">
        <el-table-column prop="accountName" label="姓名"></el-table-column>
        <el-table-column prop="phone" label="联系电话"></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              size="mini"
              plain
              icon="el-icon- plus"
              @click="onSavePerson(scope.row)"
              >添加</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="danger" @click="showPerson = false"
          >取消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.SafetyPerson {
  .el-form-item {
    margin-bottom: 0;
  }
  .fileIcon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .fileName {
    font-size: 14px;
    line-height: 16px;
  }
  .el-dialog__body {
    padding: 10px 20px;
  }
}
</style>

<script>
import SafetyMenu from "@/components/SafetyMenu.vue";

export default {
  name: "SafetyPerson",
  components: {
    SafetyMenu,
  },
  data() {
    return {
      loading: null,
      projectList: [],
      currentProject: null,

      safetyType: null,
      personList: [],
      pageNum: 1,
      pageSize: 10,
      totals: 0,

      userListData: [], // 待选用户列表
      showPerson: false,
      newSafetyType: 1,
    };
  },
  computed: {},
  mounted() {
    this.getProjectList(); // 加载项目列表数据
  },
  watch: {
    currentProject: function (newVal, oldVal) {
      this.getPersonList(); // 加载安检人员列表
      this.getUserListData(); // 获取待选用户列表数据
    },
    safetyType: function () {
      this.getPersonList(); // 加载安检人员列表
    },
  },
  methods: {
    // 加载项目列表数据
    getProjectList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        pageNum: 1,
        pageSize: 100,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/project/projectSearch",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.projectList = res.data.projectList;
          if (res.data.projectList.length) {
            that.currentProject = res.data.projectList[0].id;
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 加载安检人员列表
    getPersonList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        projectId: this.currentProject,
        safetyType: this.safetyType,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/safetyBackstagePerson/inquireTaskPerson",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("getPersonList =>", res);
          that.personList = res.data.taskPersonList;
          that.totals = res.data.totals;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 获取待选用户列表数据
    getUserListData: function () {
      var that = this;
      var data = {
        projectId: this.currentProject,
      };
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/safetyBackstagePerson/inquireSysUser",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.userListData = res.data;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 删除安检人员
    onDel: function (row) {
      var that = this;
      var data = {
        id: row.id,
      };
      this.$confirm(
        "此操作将取消安检人员角色，但并不会删除该用户, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          that.loading = that.$loading({
            lock: true,
            text: "Loading...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          that
            .$axiosAdmin({
              method: "post",
              url: "api/admin/safetyBackstagePerson/deleteTaskPerson",
              data: JSON.stringify(data),
            })
            .then((res) => {
              that.$message({
                type: "success",
                message: "操作成功!",
              });
              that.getPersonList();
              that.getUserListData();
              that.loading.close();
            })
            .catch((err) => {
              console.log(err);
              that.loading.close();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 保存安检人员
    onSavePerson: function (row) {
      var that = this;
      var data = {
        id: null,
        userId: row.userId,
        projectId: this.currentProject,
        personPhone: row.phone,
        personName: row.accountName,
        safetyType: this.newSafetyType,
      };
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/safetyBackstagePerson/insertTaskPerson",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.showPerson = false;
          that.newSafetyType = 1;
          that.getPersonList()
          that.getUserListData()
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
  },
};
</script>